import {
  IoContext_default
} from "./chunk-SXSGJTBF.mjs";
import {
  url
} from "./chunk-WM2FR7UB.mjs";

// src/useSocket.tsx
import SocketMock from "socket.io-mock";
import { useContext, useEffect, useRef, useState } from "react";
function useSocket(namespace, options) {
  var _a, _b, _c;
  const isServer = typeof window === "undefined";
  if (isServer) {
    return {
      socket: new SocketMock(),
      connected: false,
      error: null
    };
  }
  const opts = {
    namespace: typeof namespace === "string" ? namespace : "",
    options: typeof namespace === "object" ? namespace : options
  };
  const urlConfig = url(
    opts.namespace,
    ((_a = opts.options) == null ? void 0 : _a.path) || "/socket.io",
    (_b = opts.options) == null ? void 0 : _b.port
  );
  const connectionKey = urlConfig.id;
  const namespaceKey = `${connectionKey}${urlConfig.path}`;
  const enabled = ((_c = opts.options) == null ? void 0 : _c.enabled) === void 0 || opts.options.enabled;
  const { createConnection, getConnection } = useContext(IoContext_default);
  const connection = getConnection(namespaceKey);
  const state = useRef({
    socket: (connection == null ? void 0 : connection.socket) || new SocketMock(),
    status: (connection == null ? void 0 : connection.state.status) || "disconnected",
    error: null
  });
  const [, rerender] = useState({});
  const connected = state.current.status === "connected";
  useEffect(() => {
    if (enabled) {
      const {
        socket: _socket,
        cleanup,
        subscribe
      } = createConnection(urlConfig, opts.options);
      state.current.socket = _socket;
      const unsubscribe = subscribe((newState) => {
        let changed = false;
        if (state.current.status !== newState.status) {
          state.current.status = newState.status;
          changed = true;
        }
        if (state.current.error !== newState.error) {
          state.current.error = newState.error;
          changed = true;
        }
        if (changed) {
          rerender({});
        }
      });
      rerender({});
      return () => {
        unsubscribe();
        cleanup();
      };
    }
    return () => {
    };
  }, [enabled, namespaceKey]);
  return {
    socket: state.current.socket,
    error: state.current.error,
    connected
  };
}
var useSocket_default = useSocket;

export {
  useSocket_default
};
