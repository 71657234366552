// src/IoContext.tsx
import React from "react";
var IoContext = React.createContext({
  createConnection: () => void 0,
  getConnection: () => void 0,
  registerSharedListener: () => () => {
  }
});
var IoContext_default = IoContext;

export {
  IoContext_default
};
