"use client"

import { createContext, PropsWithChildren, useContext } from "react"

type AppServerConfigContextValue = {
  serverConfig: {
    environment: string
    socketHost: string
    socketPath: string
  }
}

export const AppServerConfigContext = createContext<AppServerConfigContextValue | null>(null)

export function useAppServerConfig() {
  const appServerConfig = useContext(AppServerConfigContext)
  if (!appServerConfig) {
    throw new Error("AppServerConfigContext is not available")
  }

  return appServerConfig
}

export function AppServerConfigProvider(props: PropsWithChildren<AppServerConfigContextValue>) {
  return (
    <AppServerConfigContext.Provider value={{ serverConfig: props.serverConfig }}>
      {props.children}
    </AppServerConfigContext.Provider>
  )
}
