import {
  IoContext_default
} from "./chunk-SXSGJTBF.mjs";

// src/IoProvider.tsx
import React, { useRef } from "react";
import io from "socket.io-client";
var IoProvider = function({ children }) {
  const connections = useRef({});
  const eventSubscriptions = useRef({});
  const sockets = useRef({});
  const createConnection = (urlConfig, options = {}) => {
    const connectionKey = urlConfig.id;
    if (!(connectionKey in connections.current)) {
      connections.current[connectionKey] = 1;
    } else {
      connections.current[connectionKey] += 1;
    }
    const cleanup = () => {
      if (--connections.current[connectionKey] === 0) {
        const socketsToClose = Object.keys(sockets.current).filter(
          (key) => key.includes(connectionKey)
        );
        for (const key of socketsToClose) {
          if (sockets.current[key].socket.connected) {
            sockets.current[key].socket.disconnect();
          }
          sockets.current[key].subscribers.clear();
          delete sockets.current[key];
        }
      }
    };
    const namespaceKey = `${connectionKey}${urlConfig.path}`;
    if (sockets.current[namespaceKey]) {
      sockets.current[namespaceKey].socket.connect();
      return {
        cleanup,
        ...sockets.current[namespaceKey]
      };
    }
    const handleConnect = () => {
      sockets.current[namespaceKey].state.status = "connected";
      sockets.current[namespaceKey].notify("connected");
    };
    const handleDisconnect = () => {
      sockets.current[namespaceKey].state.status = "disconnected";
      sockets.current[namespaceKey].notify("disconnected");
    };
    const socket = io(urlConfig.source, options);
    socket.namespaceKey = namespaceKey;
    sockets.current = Object.assign({}, sockets.current, {
      [namespaceKey]: {
        socket,
        state: {
          status: "disconnected",
          lastMessage: {},
          error: null
        },
        notify: (event) => {
          sockets.current[namespaceKey].subscribers.forEach(
            (callback) => callback(sockets.current[namespaceKey].state, event)
          );
        },
        subscribers: /* @__PURE__ */ new Set(),
        subscribe: (callback) => {
          var _a;
          (_a = sockets.current[namespaceKey]) == null ? void 0 : _a.subscribers.add(callback);
          return () => {
            var _a2;
            return (_a2 = sockets.current[namespaceKey]) == null ? void 0 : _a2.subscribers.delete(callback);
          };
        }
      }
    });
    socket.on("error", (error) => {
      sockets.current[namespaceKey].state.error = error;
      sockets.current[namespaceKey].notify("error");
    });
    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    return {
      cleanup,
      ...sockets.current[namespaceKey]
    };
  };
  const getConnection = (namespaceKey = "") => sockets.current[namespaceKey];
  const registerSharedListener = (namespaceKey = "", forEvent = "") => {
    if (sockets.current[namespaceKey] && !sockets.current[namespaceKey].socket.hasListeners(forEvent)) {
      sockets.current[namespaceKey].socket.on(forEvent, (message) => {
        sockets.current[namespaceKey].state.lastMessage[forEvent] = message;
        sockets.current[namespaceKey].notify("message");
      });
    }
    const subscriptionKey = `${namespaceKey}${forEvent}`;
    const cleanup = () => {
      if (--eventSubscriptions.current[subscriptionKey] === 0) {
        delete eventSubscriptions.current[subscriptionKey];
        if (sockets.current[namespaceKey])
          delete sockets.current[namespaceKey].state.lastMessage[forEvent];
      }
    };
    if (!(subscriptionKey in eventSubscriptions.current)) {
      eventSubscriptions.current[subscriptionKey] = 1;
    } else {
      eventSubscriptions.current[subscriptionKey] += 1;
    }
    return () => cleanup();
  };
  return /* @__PURE__ */ React.createElement(
    IoContext_default.Provider,
    {
      value: {
        createConnection,
        getConnection,
        registerSharedListener
      }
    },
    children
  );
};
var IoProvider_default = IoProvider;

export {
  IoProvider_default
};
