import {
  useSocket_default
} from "./chunk-67R2OED2.mjs";
import {
  IoContext_default
} from "./chunk-SXSGJTBF.mjs";

// src/useSocketEvent.tsx
import { useContext, useEffect, useRef, useState } from "react";
import SocketMock from "socket.io-mock";
function useSocketEvent(socket, event, options) {
  var _a;
  let enabled = true;
  if (typeof socket === "string") {
    const _options = event;
    options = _options;
    enabled = (_a = _options == null ? void 0 : _options.enabled) != null ? _a : true;
    event = socket;
    socket = useSocket_default(_options).socket;
  }
  let onMessage;
  let keepPrevious;
  if (options) {
    onMessage = options.onMessage;
    keepPrevious = options.keepPrevious;
  }
  const ioContext = useContext(IoContext_default);
  const { registerSharedListener, getConnection } = ioContext;
  const connection = enabled ? getConnection(socket.namespaceKey) : null;
  const [, rerender] = useState({});
  const state = useRef({
    socket: (connection == null ? void 0 : connection.socket) || new SocketMock(),
    status: (connection == null ? void 0 : connection.state.status) || "disconnected",
    error: null,
    lastMessage: connection == null ? void 0 : connection.state.lastMessage[event]
  });
  const sendMessage = (...message) => new Promise((resolve, _reject) => {
    socket.emit(
      event,
      ...message,
      (response) => {
        resolve(response);
      }
    );
  });
  useEffect(() => {
    if (!connection)
      return;
    const cleanup = registerSharedListener(
      socket.namespaceKey,
      event
    );
    const unsubscribe = connection.subscribe((newState, _event) => {
      let changed = false;
      if (state.current.status !== newState.status) {
        state.current.status = newState.status;
        changed = true;
      }
      if (state.current.error !== newState.error) {
        state.current.error = newState.error;
        changed = true;
      }
      if (_event === "message") {
        const lastMessage = newState.lastMessage[event];
        state.current.lastMessage = lastMessage;
        if (onMessage) {
          onMessage(lastMessage);
        }
        changed = true;
      }
      if (changed) {
        rerender({});
      }
    });
    return () => {
      unsubscribe();
      if (!keepPrevious) {
        cleanup();
      }
    };
  }, [socket]);
  return { ...state.current, sendMessage };
}
var useSocketEvent_default = useSocketEvent;

export {
  useSocketEvent_default
};
